import Vue from 'vue';
import {nl} from '@braid/vue-formulate-i18n';
import errorHandler from '~/libs/form-error-handler';

import Button from '~/components/formulate/Button';
import Date from '~/components/formulate/Date';
import Datepicker from '~/components/formulate/Calendar';
import Toggle from '~/components/formulate/Toggle';
import Password from '~/components/formulate/Password';

Vue.component('Button', Button);
Vue.component('Date', Date);
Vue.component('Datepicker', Datepicker);
Vue.component('Toggle', Toggle);
Vue.component('Password', Password);

export default {
  validationNameStrategy: ['validationName', 'label', 'name', 'type'],
  library: {
    button: {
      classification: 'button',
      component: Button,
    },
    submit: {
      classification: 'button',
      component: Button,
    },
    date: {
      classification: 'date',
      component: Date,
    },
    calendar: {
      classification: 'calendar',
      component: Datepicker,
      slotProps: {
        component: ['now'],
      },
    },
    toggle: {
      classification: 'checkbox',
      component: Toggle,
    },
    password: {
      classification: 'text',
      component: Password,
    },
  },
  plugins: [nl],
  classes: {
    input(context) {
      switch (context.classification) {
        case 'button':
          return 'w-full rounded-2xl bg-primary uppercase border-t-0 border-x-0 border-b-8 border-primary-300 border-8 py-3 text-white cursor-pointer active:transform active:translate-y-1 active:border-transparent';
        case 'text':
          return 'p-3 w-full text-sm font-medium text-secondary-300 rounded placeholder:text-secondary-300 relative';
        case 'group':
          return '';
        case 'file':
          return 'appearance-none opacity-0 cursor-pointer absolute inset-0 w-full h-full z-10';
        default:
          return 'block w-full py-3 px-1 mt-2 mb-4 text-gray-800 appearance-none border-b-2 border-gray-100 focus:text-gray-500 focus:outline-none focus:border-gray-200';
      }
    },
    element({classification}) {
      switch (classification) {
        case 'box':
          return 'flex items-center px-1';
        default:
          return 'w-full relative';
      }
    },
    outer(context) {
      if (context.isGrouped) {
        return '';
      } else {
        switch (context.classification) {
          case 'button':
            return '';
          case 'radio':
            return '';
          default:
            return 'mb-4';
        }
      }
    },
    wrapper(context) {
      switch (context.classification) {
        case 'button':
          return '';
        case 'box':
          return 'w-full flex justify-start';
        default:
          return '';
      }
    },
    label(context) {
      switch (context.classification) {
        case 'box':
          return 'font-body flex-grow ml-2 text-left truncate checkbox-label';
        default:
          return 'block mt-2 text-xs font-semibold text-gray-600 uppercase';
      }
    },
    /* decorator: ({hasValue}) => {
      let base = 'border rounded border-gray focus:border-primary-light inline-block w-4 h-4 mr-2';
      if (hasValue) {
        base += ' bg-blue-light';
      }
      return base;
    }, */
    help: 'text-xs mb-1 text-gray-dark',
    error: 'text-error text-xs mb-1',
    uploadArea: 'upload-area',
    file: ' appearance-none rounded border p-3 block flex relative items-center justify-center',
    files: 'hidden',
    uploadAreaMask({attrs: {loading}}) {
      const base = 'absolute pointer-events-none flex justify-center items-center inset-0 border-2 border-dashed border-gray rounded upload-placeholder';

      if (loading) {
        return `${base} loading`;
      } else {
        return base;
      }
    },
  },
  errorHandler,
};
