import { render, staticRenderFns } from "./Popup.vue?vue&type=template&id=59be0468&"
import script from "./Popup.vue?vue&type=script&lang=js&"
export * from "./Popup.vue?vue&type=script&lang=js&"
import style0 from "./Popup.vue?vue&type=style&index=0&id=59be0468&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsCross: require('/data/www/vhgbrancheopleidingen.nl/plantenkennis-trainer/trainer/components/icons/Cross.vue').default})
