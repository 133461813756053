import releaseName from '~/graphql/queries/app/releaseName.gql';

export const actions = {
  checkForNewVersion({rootState}) {
    const interval = setInterval(async () => {
      // Most browsers don't allow network requests when page is inactive
      if (document.visibilityState === 'hidden') {
        return;
      }

      let release;

      try {
        await this.app.apolloProvider.defaultClient.query({
          query: releaseName,
          fetchPolicy: 'no-cache',
        }).then(({data}) => {
          release = data.releaseName;
        });
      } catch (e) {
        console.error(e);
        return;
      }
      const serverRelease = release.trim();

      if (serverRelease && rootState.release !== serverRelease) {
        window.location.reload();
        // Stop polling
        clearInterval(interval);
      }
    }, 60000 * 15); // every 15 minutes
  },
};
