
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    big: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    open: false,
  }),
  methods: {
    cancel() {
      this.$emit('close');
    },
  },
};
