
import activeCompetitions from '~/graphql/queries/competition/activeCompetitions.gql';

export default {
  apollo: {
    activeCompetitions: {
      pollInterval: 600000,
      query: activeCompetitions,
    },
  },
  data: () => ({
    activeCompetitions: [],
  }),
  computed: {
    navigationLinks() {
      let rankingPath = '/ranking';
      if (this.activeCompetitions.length < 2) {
        rankingPath = this.localePath({name: 'ranking-id', params: {id: this.activeCompetitions[0]?.competition.id}});
      }

      return [
        {icon: 'home', link: '/'},
        {icon: 'person', link: '/profile'},
        {icon: 'fire', link: rankingPath, disabled: !(this.member?.group?.competitions?.length)},
      ];
    },
    member() {
      return this.$auth?.user?.active_group_member;
    },
  },
};
