import { render, staticRenderFns } from "./Password.vue?vue&type=template&id=489fd2b0&"
import script from "./Password.vue?vue&type=script&lang=js&"
export * from "./Password.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsEyeSlash: require('/data/www/vhgbrancheopleidingen.nl/plantenkennis-trainer/trainer/components/icons/EyeSlash.vue').default,IconsEye: require('/data/www/vhgbrancheopleidingen.nl/plantenkennis-trainer/trainer/components/icons/Eye.vue').default})
