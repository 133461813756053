import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _27147c2f = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _2f81f649 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _1ecc2c96 = () => interopDefault(import('../pages/ranking/index.vue' /* webpackChunkName: "pages/ranking/index" */))
const _2beba036 = () => interopDefault(import('../pages/trainings.vue' /* webpackChunkName: "pages/trainings" */))
const _b0474052 = () => interopDefault(import('../pages/auth/activate.vue' /* webpackChunkName: "pages/auth/activate" */))
const _30a62ed5 = () => interopDefault(import('../pages/auth/inloggen.vue' /* webpackChunkName: "pages/auth/inloggen" */))
const _557063d8 = () => interopDefault(import('../pages/auth/moodle.vue' /* webpackChunkName: "pages/auth/moodle" */))
const _b8d5f6ba = () => interopDefault(import('../pages/auth/profiel.vue' /* webpackChunkName: "pages/auth/profiel" */))
const _22798fc4 = () => interopDefault(import('../pages/auth/registreren.vue' /* webpackChunkName: "pages/auth/registreren" */))
const _a228ded6 = () => interopDefault(import('../pages/auth/verifieer.vue' /* webpackChunkName: "pages/auth/verifieer" */))
const _c6427318 = () => interopDefault(import('../pages/auth/wachtwoord-vergeten/index.vue' /* webpackChunkName: "pages/auth/wachtwoord-vergeten/index" */))
const _0fedbfe3 = () => interopDefault(import('../pages/profile/edit.vue' /* webpackChunkName: "pages/profile/edit" */))
const _2e9d6cc8 = () => interopDefault(import('../pages/templates/default.vue' /* webpackChunkName: "pages/templates/default" */))
const _2550f029 = () => interopDefault(import('../pages/auth/sso/callback.vue' /* webpackChunkName: "pages/auth/sso/callback" */))
const _552127ca = () => interopDefault(import('../pages/auth/wachtwoord-vergeten/token.vue' /* webpackChunkName: "pages/auth/wachtwoord-vergeten/token" */))
const _71d69f46 = () => interopDefault(import('../pages/competition/_competition/index.vue' /* webpackChunkName: "pages/competition/_competition/index" */))
const _a0ef4704 = () => interopDefault(import('../pages/ranking/_id.vue' /* webpackChunkName: "pages/ranking/_id" */))
const _449a68a2 = () => interopDefault(import('../pages/training/_subject/index.vue' /* webpackChunkName: "pages/training/_subject/index" */))
const _5bbab19e = () => interopDefault(import('../pages/competition/_competition/_id/index.vue' /* webpackChunkName: "pages/competition/_competition/_id/index" */))
const _be2b3ae6 = () => interopDefault(import('../pages/training/_subject/_id/index.vue' /* webpackChunkName: "pages/training/_subject/_id/index" */))
const _e6265c78 = () => interopDefault(import('../pages/competition/_competition/_id/finish.vue' /* webpackChunkName: "pages/competition/_competition/_id/finish" */))
const _d1c6fc30 = () => interopDefault(import('../pages/training/_subject/_id/finish.vue' /* webpackChunkName: "pages/training/_subject/_id/finish" */))
const _34039207 = () => interopDefault(import('../pages/*.vue' /* webpackChunkName: "pages/*" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _27147c2f,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/profile",
    component: _2f81f649,
    pathToRegexpOptions: {"strict":true},
    name: "profile___nl"
  }, {
    path: "/ranking",
    component: _1ecc2c96,
    pathToRegexpOptions: {"strict":true},
    name: "ranking___nl"
  }, {
    path: "/trainings",
    component: _2beba036,
    pathToRegexpOptions: {"strict":true},
    name: "trainings___nl"
  }, {
    path: "/auth/activate",
    component: _b0474052,
    pathToRegexpOptions: {"strict":true},
    name: "auth-activate___nl"
  }, {
    path: "/auth/inloggen",
    component: _30a62ed5,
    pathToRegexpOptions: {"strict":true},
    name: "auth-inloggen___nl"
  }, {
    path: "/auth/moodle",
    component: _557063d8,
    pathToRegexpOptions: {"strict":true},
    name: "auth-moodle___nl"
  }, {
    path: "/auth/profiel",
    component: _b8d5f6ba,
    pathToRegexpOptions: {"strict":true},
    name: "auth-profiel___nl"
  }, {
    path: "/auth/registreren",
    component: _22798fc4,
    pathToRegexpOptions: {"strict":true},
    name: "auth-registreren___nl"
  }, {
    path: "/auth/verifieer",
    component: _a228ded6,
    pathToRegexpOptions: {"strict":true},
    name: "auth-verifieer___nl"
  }, {
    path: "/auth/wachtwoord-vergeten",
    component: _c6427318,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-vergeten___nl"
  }, {
    path: "/en/profile",
    component: _2f81f649,
    pathToRegexpOptions: {"strict":true},
    name: "profile___en"
  }, {
    path: "/en/ranking",
    component: _1ecc2c96,
    pathToRegexpOptions: {"strict":true},
    name: "ranking___en"
  }, {
    path: "/en/trainings",
    component: _2beba036,
    pathToRegexpOptions: {"strict":true},
    name: "trainings___en"
  }, {
    path: "/profile/edit",
    component: _0fedbfe3,
    pathToRegexpOptions: {"strict":true},
    name: "profile-edit___nl"
  }, {
    path: "/templates/default",
    component: _2e9d6cc8,
    pathToRegexpOptions: {"strict":true},
    name: "templates-default___nl"
  }, {
    path: "/auth/sso/callback",
    component: _2550f029,
    pathToRegexpOptions: {"strict":true},
    name: "auth-sso-callback___nl"
  }, {
    path: "/auth/wachtwoord-vergeten/token",
    component: _552127ca,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-vergeten-token___nl"
  }, {
    path: "/en/auth/activate",
    component: _b0474052,
    pathToRegexpOptions: {"strict":true},
    name: "auth-activate___en"
  }, {
    path: "/en/auth/inloggen",
    component: _30a62ed5,
    pathToRegexpOptions: {"strict":true},
    name: "auth-inloggen___en"
  }, {
    path: "/en/auth/moodle",
    component: _557063d8,
    pathToRegexpOptions: {"strict":true},
    name: "auth-moodle___en"
  }, {
    path: "/en/auth/profiel",
    component: _b8d5f6ba,
    pathToRegexpOptions: {"strict":true},
    name: "auth-profiel___en"
  }, {
    path: "/en/auth/registreren",
    component: _22798fc4,
    pathToRegexpOptions: {"strict":true},
    name: "auth-registreren___en"
  }, {
    path: "/en/auth/verifieer",
    component: _a228ded6,
    pathToRegexpOptions: {"strict":true},
    name: "auth-verifieer___en"
  }, {
    path: "/en/auth/wachtwoord-vergeten",
    component: _c6427318,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-vergeten___en"
  }, {
    path: "/en/profile/edit",
    component: _0fedbfe3,
    pathToRegexpOptions: {"strict":true},
    name: "profile-edit___en"
  }, {
    path: "/en/templates/default",
    component: _2e9d6cc8,
    pathToRegexpOptions: {"strict":true},
    name: "templates-default___en"
  }, {
    path: "/en/auth/sso/callback",
    component: _2550f029,
    pathToRegexpOptions: {"strict":true},
    name: "auth-sso-callback___en"
  }, {
    path: "/en/auth/wachtwoord-vergeten/token",
    component: _552127ca,
    pathToRegexpOptions: {"strict":true},
    name: "auth-wachtwoord-vergeten-token___en"
  }, {
    path: "/en/competition/:competition",
    component: _71d69f46,
    pathToRegexpOptions: {"strict":true},
    name: "competition-competition___en"
  }, {
    path: "/en/ranking/:id",
    component: _a0ef4704,
    pathToRegexpOptions: {"strict":true},
    name: "ranking-id___en"
  }, {
    path: "/en/training/:subject",
    component: _449a68a2,
    pathToRegexpOptions: {"strict":true},
    name: "training-subject___en"
  }, {
    path: "/en/competition/:competition?/:id",
    component: _5bbab19e,
    pathToRegexpOptions: {"strict":true},
    name: "competition-competition-id___en"
  }, {
    path: "/en/training/:subject?/:id",
    component: _be2b3ae6,
    pathToRegexpOptions: {"strict":true},
    name: "training-subject-id___en"
  }, {
    path: "/en/competition/:competition?/:id/finish",
    component: _e6265c78,
    pathToRegexpOptions: {"strict":true},
    name: "competition-competition-id-finish___en"
  }, {
    path: "/en/training/:subject?/:id/finish",
    component: _d1c6fc30,
    pathToRegexpOptions: {"strict":true},
    name: "training-subject-id-finish___en"
  }, {
    path: "/competition/:competition",
    component: _71d69f46,
    pathToRegexpOptions: {"strict":true},
    name: "competition-competition___nl"
  }, {
    path: "/ranking/:id",
    component: _a0ef4704,
    pathToRegexpOptions: {"strict":true},
    name: "ranking-id___nl"
  }, {
    path: "/training/:subject",
    component: _449a68a2,
    pathToRegexpOptions: {"strict":true},
    name: "training-subject___nl"
  }, {
    path: "/competition/:competition?/:id",
    component: _5bbab19e,
    pathToRegexpOptions: {"strict":true},
    name: "competition-competition-id___nl"
  }, {
    path: "/training/:subject?/:id",
    component: _be2b3ae6,
    pathToRegexpOptions: {"strict":true},
    name: "training-subject-id___nl"
  }, {
    path: "/competition/:competition?/:id/finish",
    component: _e6265c78,
    pathToRegexpOptions: {"strict":true},
    name: "competition-competition-id-finish___nl"
  }, {
    path: "/training/:subject?/:id/finish",
    component: _d1c6fc30,
    pathToRegexpOptions: {"strict":true},
    name: "training-subject-id-finish___nl"
  }, {
    path: "/en/*",
    component: _34039207,
    pathToRegexpOptions: {"strict":true},
    name: "*___en"
  }, {
    path: "/",
    component: _27147c2f,
    pathToRegexpOptions: {"strict":true},
    name: "index___nl"
  }, {
    path: "/*",
    component: _34039207,
    pathToRegexpOptions: {"strict":true},
    name: "*___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
