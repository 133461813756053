import {BaseScheme} from '@nuxtjs/auth-next/dist/runtime';
import Token from '~/plugins/token';

import meQuery from '~/graphql/queries/user/me.gql';

import loginMutation from '~/graphql/mutations/auth/login.gql';
import logoutMutation from '~/graphql/mutations/auth/logout.gql';

const DEFAULTS = {
  name: 'apollo',
  token: {
    property: 'token',
    type: 'Bearer',
    name: 'Authorization',
    maxAge: 60 * 60 * 24 * 180, // 180 days in seconds
    global: true,
    required: true,
    prefix: '_token.',
    expirationPrefix: '_token_expiration.',
  },
  user: {
    property: 'user',
    autoFetch: true,
  },
};

export default class ApolloScheme extends BaseScheme {
  constructor(auth, options) {
    const opts = {...DEFAULTS, ...options};
    super(auth, opts);

    this.$auth = auth;
    this.$apollo = auth.ctx.app.apolloProvider.defaultClient;
    this.token = new Token(this, this.$auth.$storage);
    this._name = options.name;
    this._apolloCache = auth.ctx.app.apolloProvider.defaultClient.cache;
  }

  async login({data}) {
    const {data: {login}} = await this.$apollo.mutate({
      mutation: loginMutation,
      variables: data,
    });
    await this.$auth.setUserToken(login);
    await this.fetchUser();
  }

  fetchUser() {
    if (!this.$auth.check().valid) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(false);
    }

    return this.$apollo.query({query: meQuery, fetchPolicy: 'no-cache'})
      .then(({data: {me}}) => me).then((data) => {
        if (data) {
          if (!data.active_group_member) {
            this.$auth.logout();
            window.location.href = 'https://portal.vhgbrancheopleiding.nl/';
          } else {
            this.$auth.setUser(data);
          }
        }
      });
  }

  async logout() {
    await this.reset();
  }

  async reset() {
    await this.$apollo.mutate({
      mutation: logoutMutation,
    });

    await this._apolloCache.reset();
    await this.$auth.setUserToken(null, null);

    return this.$auth.setUser(null);
  }

  check() {
    const cookieName = `${this.$auth.options.cookie.prefix}_token.${this._name}`;
    const cookies = this.$auth.$storage.getCookies();

    return {
      valid: Boolean(cookies[cookieName]),
    };
  }
}
