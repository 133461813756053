
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    visible: false,
  }),
  methods: {
    change() {
      if (this.context.model.length === 0) {
        this.visible = false;
      }
    },
  },
};
