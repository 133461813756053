const scrollToError = (formName) => {
  setTimeout(() => {
    const errorWrapperEl = document.querySelector(`form[name=${formName}] [data-has-errors="true"]`);
    const errorWrapperInput = errorWrapperEl && errorWrapperEl.querySelector('input,select,textarea');

    if (errorWrapperInput) {
      errorWrapperInput.focus();
      errorWrapperInput.scrollIntoView({block: 'center'});
    }
  });
};

// Todo: formErrors are always set to null, maybe we can use them in $toast?
export default ({graphQLErrors, response}, formName) => {
  let validation = null;

  if (graphQLErrors) {
    const validationErrors = graphQLErrors.find(error => error.message === 'validation');

    if (validationErrors) {
      validation = validationErrors.extensions.validation;
    }
  }

  if (validation) {
    scrollToError(formName);

    return {
      inputErrors: validation,
      formErrors: null,
    };
  } else if (response && response.status) {
    scrollToError(formName);

    switch (response.status) {
      case 422:
        return {
          inputErrors: response.data.errors,
          formErrors: null,
        };
    }  
  }

  return {
    inputErrors: {},
    formErrors: null,
  };
};
