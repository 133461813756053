import { render, staticRenderFns } from "./index.vue?vue&type=template&id=c50b3eb0&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconsFeedback: require('/data/www/vhgbrancheopleidingen.nl/plantenkennis-trainer/trainer/components/icons/Feedback.vue').default,Popup: require('/data/www/vhgbrancheopleidingen.nl/plantenkennis-trainer/trainer/components/base/Popup.vue').default})
