
import sendFeedback from '~/graphql/mutations/feedback/sendFeedback.gql';

export default {
  data: () => ({
    visible: false,
    content: null,
  }),
  methods: {
    closePopup() {
      this.content = '';
      this.visible = false;
    },
    async submit() {
      try {
        await this.$apollo.mutate({
          mutation: sendFeedback,
          variables: {
            content: this.content,
            route: this.$route.fullPath,
          },
        }).then(({data}) => {
          if (data.sendFeedback) {
            this.$toast.success('Je feedback is succesvol verstuurd');
            this.content = '';
            this.visible = false;
          } else {
            this.$toast.error('Er is iets fout gegaan met versturen van je feedback. Probeer het later nog eens');
            this.visible = false;
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
  },
};
